@font-face {
  font-family: "Kanit";
  src: url("/public/resources/fonts/Kanit-Black.ttf") format("truetype");
}

@font-face {
  font-family: "KanitL";
  src: url("/public/resources/fonts/Kanit-Regular.ttf") format("truetype");
}

.App {
  /* text-align: center; */
  font-family: "Kanit";
}

p {
  font-family: "KanitL";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav {
  background-color: #f2f2f2;
}

html.is-changing .transition-fade {
  transition: opacity 0.25s;
  opacity: 1;
}
html.is-animating .transition-fade {
  opacity: 0;
}

header > h1 {
  text-align: center;
  font-family: "Kanit";
  color: #fff;
  /* font-size: 3rem; */
  text-shadow: 3px 3px 5px #0ac7ca;
}

input,
textarea {
  border-width: 0px !important;
}

label {
  padding-bottom: 5px !important;
  margin-bottom: 5px !important;
  font-size: 18px !important;
}

footer {
  background-color: transparent !important;
}

footer img {
  height: 60px !important;
}

#services p {
  color: #f2f2f2;
}

#astronaut > img {
  padding-top: 400px;
  display: block;
  left: 25%;
  animation: anim 20s linear infinite;
  position: absolute;
  width: 150px;
}

#astronaut2 > img {
  padding-top: 180px;
  display: block;
  left: 15%;
  position: absolute;
  animation: anim2 20s linear infinite;
  animation-delay: 1s;
  width: 150px;
}

@media only screen and (min-width: 768px) {
  #astronaut > img {
    padding-top: 400px;
    display: block;
    left: 75%;
    animation: anim 20s linear infinite;
    position: absolute;
    width: 250px;
  }

  #astronaut2 > img {
    padding-top: 150px;
    display: block;
    left: 15%;
    position: absolute;
    animation: anim2 20s linear infinite;
    animation-delay: 1s;
    width: 250px;
  }
}

@keyframes anim {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(30px) rotate(10deg);
  }
  20% {
    transform: translateY(60px) rotate(20deg);
  }
  30% {
    transform: translateY(90px) rotate(30deg);
  }
  40% {
    transform: translateY(120px) rotate(20deg);
  }
  50% {
    transform: translateY(150px) rotate(30deg);
  }
  60% {
    transform: translateY(120px) rotate(40deg);
  }
  70% {
    transform: translateY(90px) rotate(10deg);
  }
  80% {
    transform: translateY(60px) rotate(20deg);
  }
  90% {
    transform: translateY(30px) rotate(10deg);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes anim2 {
  0% {
    transform: translateY(0px);
  }
  10% {
    transform: translateY(-30px) rotate(-10deg);
  }
  20% {
    transform: translateY(-60px) rotate(-20deg);
  }
  30% {
    transform: translateY(-90px) rotate(-10deg);
  }
  40% {
    transform: translateY(-120px) rotate(0deg);
  }
  50% {
    transform: translateY(-150px) rotate(-10deg);
  }
  60% {
    transform: translateY(-120px) rotate(0deg);
  }
  70% {
    transform: translateY(-90px) rotate(20deg);
  }
  80% {
    transform: translateY(-60px) rotate(10deg);
  }
  90% {
    transform: translateY(-30px) rotate(0deg);
  }
  100% {
    transform: translateY(0px);
  }
}
